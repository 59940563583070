// src/components/ReservationList.jsx
import React from 'react';

const ReservationList = ({ reservations }) => {
    return (
        <div>
            <h3>Lista de Reservas</h3>
            <ul>
                {reservations.map((reservation, index) => (
                    <li key={index}>
                        {reservation.summary} - {new Date(reservation.startTime).toLocaleString()} a {new Date(reservation.endTime).toLocaleString()}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ReservationList;
