// src/pages/ReservarSala.jsx
import React, { useState, useEffect } from 'react';
import ReservationForm from '../components/ReservationForm'; // Componente para el formulario
import ReservationList from '../components/ReservationList'; // Componente para mostrar las reservas
import GoogleCalendar from '../components/GoogleCalendar'; // Componente para cargar Google Calendar
import { Container } from 'react-bootstrap';

const ReservarSala = () => {
    const [reservations, setReservations] = useState([]);

    // Función para agregar nuevas reservas
    const addNewReservation = (newReservation) => {
        setReservations([...reservations, newReservation]);
    };

    return (
        <Container>
            <h1 style={{ color: "#592582" }}>Reserva de salas Velonet</h1>
            <br />
            {/* Formulario de reserva */}
            <ReservationForm addReservation={addNewReservation} />
            <br />
            {/* Mostrar la lista de reservas */}
            <ReservationList reservations={reservations} />
            <br />
            {/* Mostrar el calendario de Google */}
            <GoogleCalendar reservations={reservations} setReservations={setReservations} />
        </Container>
    );
};

export default ReservarSala;
