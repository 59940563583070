// src/components/ReservationForm.jsx
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const ReservationForm = ({ addReservation }) => {
    const [newReservation, setNewReservation] = useState({
        summary: '',
        startTime: '',
        endTime: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewReservation(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí agregarías la lógica para guardar la reserva en el backend si lo necesitaras
        addReservation(newReservation); // Agrega la nueva reserva al estado de reservas
        setNewReservation({
            summary: '',
            startTime: '',
            endTime: ''
        });
        alert('Reserva realizada correctamente');
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="summary">
                <Form.Label>Resumen de la Reserva</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Ingrese el resumen de la reserva"
                    name="summary"
                    value={newReservation.summary}
                    onChange={handleInputChange}
                    required
                />
            </Form.Group>
            <Form.Group controlId="startTime">
                <Form.Label>Hora de Inicio</Form.Label>
                <Form.Control
                    type="datetime-local"
                    name="startTime"
                    value={newReservation.startTime}
                    onChange={handleInputChange}
                    required
                />
            </Form.Group>
            <Form.Group controlId="endTime">
                <Form.Label>Hora de Fin</Form.Label>
                <Form.Control
                    type="datetime-local"
                    name="endTime"
                    value={newReservation.endTime}
                    onChange={handleInputChange}
                    required
                />
            </Form.Group>
            <Button variant="primary" type="submit">
                Reservar Sala
            </Button>
        </Form>
    );
};

export default ReservationForm;
