import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import CardsConvenios from '../modules/Cards/CardsConvenios'; // Ruta corregida
import Footer from '../components/Footer';
import CardsImagen from '../modules/Cards/CardsImagen';


const VeloCx = () => {
  return (
    <>
    <Container>
      <h1 style={{ color: "#592582" }}>Velo CX</h1>
      <p>Boletín de experiencia al cliente</p>
      <Row>
      <Col md={4}>
          <CardsConvenios
            title="Noviembre"
            buttonText="Ver Boletín"
            margin="20px"
            imageSrc="/assets/img/veloCx/BoletinNoviembre.jpg"
            link="../assets/img/veloCx/BoletinNoviembre.jpg"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Octubre"
            buttonText="Ver Boletín"
            margin="20px"
            imageSrc="/assets/img/veloCx/BoletinOctubre.jpg"
            link="../assets/img/veloCx/BoletinOctubre.jpg"
          />
        </Col>
      <Col md={4}>
          <CardsConvenios
            title="Septiembre"
            buttonText="Ver Boletín"
            margin="20px"
            imageSrc="/assets/img/veloCx/BoletinSeptiembre.png"
            link="../assets/img/veloCx/BoletinSeptiembre.png"
          />
        </Col>
      <Col md={4}>
          <CardsConvenios
            title="Agosto"
            buttonText="Ver Boletín"
            margin="20px"
            imageSrc="/assets/img/veloCx/BoletinAgosto.png"
            link="../assets/img/veloCx/BoletinAgosto.png"
          />
        </Col>
      <Col md={4}>
          <CardsConvenios
            title="Julio"
            buttonText="Ver Boletín"
            margin="20px"
            imageSrc="/assets/img/veloCx/BoletinJulio.jpg"
            link="../assets/img/veloCx/BoletinJulio.jpg"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Junio"
            buttonText="Ver Boletín"
            margin="20px"
            imageSrc="/assets/img/veloCx/BoletinJunio.jpg"
            link="../assets/img/veloCx/BoletinJunio.jpg"
          />
        </Col>
        <Col md={4}>
          <CardsConvenios
            title="Mayo"
            buttonText="Ver Boletín"
            margin="20px"
            imageSrc="/assets/img/veloCx/BoletinMayo.png"
            link="../assets/img/veloCx/BoletinMayo.png"
          />
        </Col>
      </Row>
    </Container>
    <Footer />
    </>
  );
};

export default VeloCx;
